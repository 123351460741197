import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../theme";
import { keyframes } from "@emotion/react";

const bg = require("./assets/bg.png");

const showIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const StyledFooter = styled("div")({
  minHeight: "50px",
  backgroundImage: `url(${bg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  animation: `${showIn} .5s ease forwards 1s`,
  opacity: 0,
  [breakpointMax("md")]: {
    backgroundPosition: "left",
  },
});
export const StyledCopyright = styled("div")({
  width: "70%",
  margin: "auto",
  height: calcRem(60),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledCopyrightText = styled("p")({
  fontSize: calcRem(16),
  color: colors.white,
  animation: `${showIn} 1s ease forwards 1.5s`,
  opacity: 0,
  [breakpointMax("xxl")]: {
    fontSize: calcRem(14),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(13),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(12),
  },
});
